module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-datadog/gatsby-browser.js'),
      options: {"plugins":[],"site":"datadoghq.com","sampleRate":100,"enabled":true,"rum":{"applicationId":"22ba902c-578b-4aa4-9db2-6bf559690836","clientToken":"pubc275f1ea9c681b56aa56bb9df1b4626b","service":"a2z-digital-retail","env":"qa6","trackInteractions":true},"logs":{"clientToken":"pubc275f1ea9c681b56aa56bb9df1b4626b","service":"a2z-digital-retail","env":"qa6"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
